
import { defineComponent, onMounted, ref, reactive, onBeforeMount } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "sign-up",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  data() {
    return {
      divisionList: {},
    };
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const submitButton = ref<HTMLElement | null>(null);

    const registration = Yup.object().shape({
      name: Yup.string().required().label("First Name"),
      last_name: Yup.string().required().label("Second Name"),
      //division_id: Yup.number().label("Division"),
      role: Yup.string().required().min(2).max(150).label("Job role"),
      email: Yup.string().min(4).required().email().label("Email"),
      phone: Yup.string().min(5).max(50).required().label("Phone"),
      password: Yup.string().min(8).required().label("Password"),
      password_confirmation: Yup.string()
        .min(8)
        .required()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .label("Password Confirmation"),
      terms_and_condition: Yup.number().required(
        "Must accept Terms and Condition"
      ),
    });

    const onSubmitRegister = async (values) => {
      // Clear existing errors
      store.dispatch(Actions.LOGOUT);

      // Activate indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      // Dummy delay
      await store
          .dispatch(Actions.REGISTER, values)
          .then(() => {
            Swal.fire({
              text: "Thank you! Now you need to verify your account, check your email for the instruction",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              // Go to page after successfully login
              router.push({ name: "sign-in" });
            });
          })
          .catch(() => {
            const errs = JSON.parse(store.getters.getErrors);

            console.log(errs);
            let errTxts = '<ul>';
            for (const property in errs) {
              errTxts += `<li>${property}: ${errs[property]}</li>`;
            }
            errTxts += '</ul>';
            
            if (errs.hasOwnProperty("email")) {
              errTxts = 'Your email is already registered. Please try a different email address to register, or go to <a href="/sign-in" title="Sign in">sign in</a> to access your account.';
            }
            
            Swal.fire({
              html: errTxts,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });

        submitButton.value?.removeAttribute("data-kt-indicator");
      };

    return {
      registration,
      onSubmitRegister,
      submitButton,
    };
  },

  methods: {
    async getDivision() {
      this.divisionList = await ApiService.get("division/list")
        .then(({ data }) => {
          return data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
  },

  mounted() {
    this.getDivision();
  },
});
